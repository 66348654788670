@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap'); 
@import url('https://fonts.googleapis.com/css2?family=DotGothic16&display=swap');
.App {
  text-align: center;
}


body {
  font-family: 'DotGothic16', sans-serif;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Orbitron', sans-serif;
}
