@import url(https://fonts.googleapis.com/css2?family=Orbitron&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DotGothic16&display=swap);
body {
  background-color: #2c0280e8;
  background-image: url(../../static/media/BackgroundStill.195d8e74.webp);
  object-fit: cover;
  z-index: -999;
  width: 100vw;
  min-height: 100vh;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
}

.App {
  overflow-y: hidden;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-family: 'DotGothic16', sans-serif;
}
.background {
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
header {
  position: fixed;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  width: 100%;
  padding: 1rem;
  background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5));
  z-index: 998;
}
.headerText {
  display: inline-block;
  font-size: 20px;
  padding: 5px;
  text-decoration: none;
  color: #ffffff;
}
.headerTextbolts {
  display: inline-block;
  font-size: 20px;
  padding-right: 5px;
  padding-bottom: 5px;
  text-decoration: none;
  color: #ffffff;
}
.headerTextDisabled {
  display: inline-block;
  font-size: 1.3rem;
  padding: 5px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.4);
  pointer-events: none;
}
.Wallet {
  justify-content: center;
  display: flex;
  position: relative;
  padding-top: 10px;
}
.LandingPage{
  /* padding-bottom: 100px; */
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}
.LandingLogo {
  width: clamp(57%, 800px, 90%);
  height: min(80%, 400px);
}
.Roadmap {
  display: flex;
  padding-top: 10rem;
  padding-bottom: 5rem;
  align-items: center;
  justify-content: center;
}
.RoadmapImg {
  width: clamp(75%, 800px, 90%);
  height: min(80%, 400px);
  border-radius: 12px;
}
.banner{
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 200px;
  width: 100%;
  background-color: rgba(240,55,128, 0.75);
  margin-top: 4em;
  margin-bottom:2em;
}
.bannerText {
  color:#fff;
}
.container {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: white;
}
.mintPage {
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 600px;
  min-height: 560px;
  border-radius: 12px;
  background-color: rgba(240,55,128, 0.75);
}
.mintImage {
  border-radius: 50%;
}
.countContainer{
  justify-content:center;
  text-align: center;
  align-items: center;
  display: flex;
}
.mintAmount {
  font-size: 2em;
  padding-top: 1rem;
}
.mathButton {
  font-size: 1.5em;
  min-width: 2rem;
  min-height: 2rem;
  border-radius: 12px;
  margin: 2rem;
  background-color: rgba(33, 0, 247, 0.75);
}

.descCtn {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.desc {
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 600px;
  border-radius: 12px;
  background-color: rgba(240,55,128, 0.75);
  padding: 1rem;
}

.marketCtn {
  display: flex;
  margin-top: 1em;
  align-items: center;
  /* text-align: center; */
  justify-content: center;
  cursor: pointer
}

.market{
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 600px;
    border: white 1px solid;
    /* border-radius: 12px; */
    /* background-color: rgba(240,55,128, 0.75); */
}
.market-img{
  width: 100%
}
.mintTitle{
  font-size: 2em;
  font-weight: bold;
}
.UserCount {
  font-size: 1.5em;
  padding-top: 1rem;
  font-weight: 900;
}

.UserClankCount {
  font-size: 1.5em;
  padding-top: 1rem;
  font-weight: 900;
}

.ClaimButton {
  font-size: 1.5em;
  font-weight: 900;
  background-color: rgba(240,55,128, 1);
  border-radius: 12px;
  padding: .75rem;
  min-width: 128px;
  border: 2px solid black;
  box-shadow: 4px 3px;
  cursor: pointer;
}

.ClaimButton:hover {
  background-color: rgb(161, 44, 91);
  min-width: 112px;

}

.mintdescription {
  font-size: 20px;
  font-weight: bold;
  color: #fff 
}
.mintButton {
  font-size: 1.25em;
  min-width: 7rem;
  min-height: 3rem;
  border-radius: 12px;
  background-color: rgba(33, 0, 247, 0.75);
  cursor: pointer;
}
.RobosTeam {
  display: flex;
  padding: 3rem;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.TeamTitle {
  color: #ffffff;
  font-size: 2rem;
  padding-top: 4rem;
}
.card-list {
  display: flex;
  padding: 1rem;
  overflow-x: scroll;
}
.card {
  height: 400px;
  width: 250px;
  min-width: 200px;
  padding: 1rem;
  border-radius: 16px;
  background: #2c0280;
  box-shadow: -1rem 0 3rem #000;
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: .2s;
  margin: 0;
  scroll-snap-align: start;
  clear: both;
  position: relative;
  z-index: 0;
}
.card:focus-within~.card, .card:hover~.card {
  -webkit-transform: translateX(172px);
          transform: translateX(172px);
}
.card:hover {
  -webkit-transform: translateY(-2rem);
          transform: translateY(-2rem);
}
.card:not(:first-child) {
  margin-left: -130px;
}
.card-header {
  z-index: 999;
}
.card-header p {
  font-size: 14px;
  margin: 0 0 1rem;
  color: #7a7a8c;
}
.card-header h2 {
  font-size: 20px;
  margin: .25rem 0 auto;
  text-decoration: none;
  color: #fff;
  border: 0;
  display: inline-block;
  cursor: pointer;
}
.card-header h2:hover {
  background: linear-gradient(90deg,#ff8a00,#e52e71);
  text-shadow: none;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
.author-avatar {
  grid-area: auto;
  align-self: start;
  position: relative;
  box-sizing: border-box;
}
.author-avatar img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: block;
  overflow: hidden;
  margin: 16px 10px;
}
.author-name {
  grid-area: auto;
  box-sizing: border-box;
  color: #fff;
}
.author-name-prefix {
  font-style: normal;
  font-weight: 700;
  color: rgba(240,55,128, 0.75);
}
.tags {
  margin: 1rem 0 2rem;
  padding: .5rem 0 1rem;
  line-height: 2;
  margin-bottom: 0;
}
.tags a {
  font-style: normal;
  font-weight: 700;
  font-size: .5rem;
  color: rgba(240,55,128, 0.75);
  text-transform: uppercase;
  font-size: .66rem;
  border: 3px solid #28242f;
  border-radius: 2rem;
  padding: .2rem .85rem .25rem;
  position: relative;
}
.tags a:hover {
  background: linear-gradient(90deg,#ff8a00,#e52e71);
  text-shadow: none;
  -webkit-background-clip: text;
          background-clip: text;
  border-color: white;
}
.accordion-page {
  display: flex;
  justify-content: center;
  padding: 4rem;
  font-family: 'DotGothic16', sans-serif;
  ;
}
.accordion {
  width: clamp(60%, 700px, 90%);
  height: min(50%, 300px);
  font-family: 'DotGothic16', sans-serif;
  ;
}
.questions {
  text-align: left;
  width: 100%;
  padding: 1rem;
  font-family: 'DotGothic16', sans-serif;
  ;
}
.anwsers {
  text-align: center;
  padding: 1rem;
}
.links {
  font-family: 'DotGothic16', sans-serif;
  ;
  text-decoration: none;
  color: #fff;
}
.App-Footer {
  padding-bottom: 40px;
  z-index: 999;
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  min-height: 98px;
  max-height: 169px;
  padding: 1rem;
  background: rgba(0, 0, 0, 1);
}
.FooterTitle {
  color: #fff;
  font-size: 1.25rem;
  justify-self: flex-start;
}
.FooterText {
  display: inline;
  padding: 1rem;
  color: #fff;
  justify-content: center;
}
a.FooterHome {
  min-width: 75px;
  min-height: 102px;
}
.footerlogo {
  max-width: 35px;
  max-width: 35px;
  padding: 5px;
  align-items: center;
  text-align: center;
}
.FooterApp-logo {
  padding-top: 1.5rem;
  pointer-events: none;
}
.GlitchesLogo {
  padding-left: 1rem;
  max-width: 175px;
  align-items: center;
  text-align: center;
}
.Footer-left {
  width: 33%;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}
.Footer-center {
  width: 33%;
  justify-content: flex-center;
  align-items: center;
  text-align: center;
}
.Footer-right {
  width: 33%;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  header {
      padding: 0.2rem;
  }
  .headerText {
      font-size: 14px;
      padding: 2px;
  }
  .headerTextbolts {
      font-size: 14px;
      padding-right: 2px;
      padding-bottom: 2px;
  }
  .headerTextDisabled {
      font-size: 14px;
      padding: 2px;
  }
  .Wallet {
      padding-top: 5px;
  }
  .RoadmapImg {
      width: clamp(150%, 800px, 90%);
      height: auto;
      border-radius: 12px;
  }
  .RobosTeam {
      display: block;
      padding: 4rem;
      align-items: center;
      text-align: center;
      justify-content: center;
  }
  .card:focus-within~.card, .card:hover~.card {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
  }
  .card:hover {
      -webkit-transform: translateY(0rem);
              transform: translateY(0rem);
  }
  .card:not(:first-child) {
      margin-left: 0px;
  }
  .accordion-page {
      display: flex;
      justify-content: center;
      padding: 1rem;
      font-family: 'DotGothic16', sans-serif;
      ;
  }
  .accordion {
      width: clamp(30%, 700px, 90%);
      height: min(50%, 300px);
      font-family: 'DotGothic16', sans-serif;
      ;
  }
}
.App {
  text-align: center;
}


body {
  font-family: 'DotGothic16', sans-serif;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Orbitron', sans-serif;
}

